import React from 'react';
import { ReactP5Wrapper } from 'react-p5-wrapper';
import '../styles/animate.css';
const NUM_RECTS = 30;
const RECT_WIDTH =15;
const RECT_HEIGHT = 70;
const SPEED_FACTOR = 0.01;

const Sketch = (p5) => {
  let rects = [];

  p5.setup = () => {
    p5.createCanvas(250, 50, 'transparent'); // Increase canvas width to accommodate two sets of rectangles side by side
    for (let i = 0; i < NUM_RECTS; i++) {
      rects.push(new MoveRect(i + 1, 0)); // Create first set of rectangles with an offset of 0
    }
  };

  p5.draw = () => {
    p5.clear();
    for (let rect of rects) {
      rect.update();
      rect.show();
    }
  };

  class MoveRect {
    constructor(index, xOffset) {
      this.index = index;
      this.x = RECT_WIDTH + (this.index - 1) * RECT_WIDTH + xOffset; // Add xOffset to position the second set of rectangles
      this.y = 0;
      this.red = 100;
      this.green = 200;
      this.blue = 100;
      this.time = 0;
    }

    update() {
		this.time += SPEED_FACTOR;
		this.y = 100 + p5.sin(this.time * (4 + this.index / 10)) * 100;
		let redHundred = 0.8 + this.index / 2 / 10;
		let greenHundred = 0.2 + this.index / 2 / 10;
		this.red = p5.map(redHundred, 0, 1, 0, 253);
		this.green = p5.map(greenHundred, 0, 1, 0, 255);
		this.blue = 100; // You can adjust the blue value if needed
	  }

    show() {
      p5.fill(this.red, this.green, this.blue,150);
      p5.strokeWeight(0);
      p5.rect(this.x, this.y, RECT_WIDTH, RECT_HEIGHT, 10);
    }
  }
};

const MovingRectangles = () => (
  <div id="moving-rectangles">
    <ReactP5Wrapper sketch={Sketch} />
  </div>
);

export default MovingRectangles;
