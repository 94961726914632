import React from "react";
import { ReactP5Wrapper } from "react-p5-wrapper";
import "../styles/tree.css";

let angle;
let branchLength = 100; // Initial branch length
let scaleValue = 0.5; // Scale factor for the drawing
let canvasSize = 200; // Size of the canvas

const Sketch = p5 => {
  p5.setup = () => {
    p5.createCanvas(canvasSize, canvasSize, "transparent"); // Set the size of the canvas
    angle = p5.PI / 4;
    p5.stroke('#BBAB8C');
  };

  p5.draw = () => {
    p5.clear();
    p5.translate(canvasSize / 2, canvasSize); // Translate to the center of the canvas
    p5.scale(scaleValue); // Scale the drawing
    angle = p5.map(p5.sin(p5.frameCount * 0.01), -1, 1, p5.PI / 2, p5.PI / 16); // vary the angle using sin()
    branch(branchLength); // Pass branch length as a parameter
  };

  function branch(len) {
    p5.line(0, 0, 0, -len);
    p5.translate(0, -len);
    if (len > 4) {
      p5.push();
      p5.rotate(angle);
      branch(len * 0.67);
      p5.pop();
      p5.push();
      p5.rotate(-angle);
      branch(len * 0.67);
      p5.pop();
    }
  }
};

const Tree = () => (
  <div id="fractal-tree">
    <ReactP5Wrapper sketch={Sketch} />
  </div>
);

export default Tree;