import React from 'react';
import '../styles/about.css';
import FadeInSection from './FadeInSection';

class About extends React.Component {
	constructor() {
		super();
		this.state = {
			expanded: true,
			activeKey: '1',
		};
		this.handleSelect = this.handleSelect.bind(this);
	}
	handleSelect(eventKey) {
		this.setState({
			activeKey: eventKey,
		});
	}
	render() {
		const one = (
			<p>
				I am currently undergrad junior. I am looking for 
        <a href=""> Technical PM or Software Engineer </a> positions.
			  I believe I have a background of both engineering and project management that's why I am interested in both of these roles.
			</p>
		);
		const two = (
			<div>
				<p >
				Although I am a girl of diverse interests, and my downtime is often dedicated
				playing badminton. My top three favorite pastimes include watching tv shows, cafe hopping and playing video games.
				
			</p>
			</div>

		);
		
		
		const desc_items = [one, two];

		const tech_stack = [
			'Java',
			'C++',
			'Unity',
			'Python',
			'Nodejs',
			'Mongodb',
			'Reactjs',
			'Expressjs',
			'Blender' ,
			'JS, HTML, CSS',
		];
		const tech_items = tech_stack.map((stack) => <li>{stack}</li>);
		return (
			<div id="about">
			<FadeInSection>
				<div className="section-header ">
				<span className="section-title"><b>/ about</b></span>
				</div>
				<div className="about-content">
				<div className="about-description">
					{[one]}
					{"Here are some technologies I have been working with:"}
					<ul className="tech-stack">
					{tech_stack.map(function (tech_item, i) {
						return (
						<FadeInSection delay={`${i + 1}00ms`}>
							<li>{tech_item}</li>
						</FadeInSection>
						);
					})}
					</ul>
					{[two]}
				</div>
				
				<div className="about-image">
					<img src="../mat/me.jpg" alt="" />
				</div>
				

				</div>
			</FadeInSection>
			</div>
		);
	}
}

export default About;
