import React from 'react';
import FadeInSection from './FadeInSection';
import EmailIcon from '@mui/icons-material/Email';
import Face3Icon from '@mui/icons-material/Face3';
import '../styles/Intro.css';
import Typewriter from 'typewriter-effect';
import Animate from './animate';
import { ReactComponent as UpSvg } from './up.svg';
import Tree from "./tree";

class Intro extends React.Component {
	constructor() {
		super();
		this.state = {
			expanded: true,
			activeKey: '1',
			visible: true,
		};
		this.handleSelect = this.handleSelect.bind(this);
	}
	handleSelect(eventKey) {
		this.setState({
			activeKey: eventKey,
		});
	}
	render() {
		return (
			<><div><UpSvg style={{ transform: 'translateY(0)', background: 'var(--background)' }} /></div>
			<div id="intro">
			<div height= "100px" width = "100px" align= "center"><Tree /></div>
					<span className="introTitle" id="typewriter">
					<div align= "center"><Animate /></div>
						<Typewriter
							onInit={(typewriter) => {
								typewriter
									.typeString('hi! I am ')
									.typeString(
										'<span style="color: var(--highlight); font-weight: bold;">✩Samiya</span>'
										
									)
									.typeString(
										'<span style="color: var(--highlight); font-weight: bold;">✩</span>'	
									)

									.pauseFor(1000)
									// .deleteAll()
									.start();
							} } />
					</span>
					
					<FadeInSection>
						<div className="introSubtitle">powered by spicy ramen & iced coffee </div>
						<div className="introDescription">
							I'm a fullstack developer with a little interest in web and game dev.
							I like making games and reading about all the jarring AI updates. 
							I also like exploring cafes in the city and watching tv shows.
						</div>
						<div>
							<div style={{ display: 'inline-block', marginRight: '50px' }}>
								<a href="mailto:samiyanur142@gmail.com" className="introContact">
									<EmailIcon /> {' ' + 'Say hi!'}
								</a>
							</div>
							<div style={{ display: 'inline-block' }}>
								<a href="https://samiyatech.netlify.app/" className="introContact">
									<Face3Icon />{'  ' + ' 3d Portfolio'}
								</a>
							</div>
						</div>

					</FadeInSection>
					
				</div></>
		);
	}
}
export default Intro;
