import React from "react";
import "../styles/projects.css";
import FolderOpenRoundedIcon from '@mui/icons-material/FolderOpenRounded';
import FadeInSection from "./FadeInSection";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Mylinks from "./Mylinks";

class Projects extends React.Component {
  constructor() {
    super();
    this.state = {
      expanded: true,
      activeKey: "1"
    };
    this.handleSelect = this.handleSelect.bind(this);
  }
  handleSelect(eventKey) {
    this.setState({
      activeKey: eventKey
    });
  }
  render() {
    const projects = {
    'Lending Club Loan Analysis': {
      desc: 'Performed data cleaning and data preparation for predictive analytics to understand loan types and lending behavior. Then, employed predictive models for developing various investment strategies.',
      techStack: 'R, K-means, Logistic Regression, Visualization',
      link: 'https://github.com/jojo142/LendingClubAnalysis',
      open: 'https://github.com/jojo142/LendingClubAnalysis/blob/main/README.md',
    },
    'LLM: Detecting AI Generated Texts': {
      desc: 'Developed a machine learning model to detect AI-generated texts. The model was trained on a dataset of 1000 AI-generated texts and 1000 human-written texts. The model was able to detect AI-generated texts with 95% accuracy.',
      techStack: 'Python, Machine Learning, SVM, NLP, BERT, TF-IDF, Word2Vec',
      link: 'https://github.com/jojo142/Detecting-AI-Generated-Text',
      open: 'https://www.kaggle.com/competitions/llm-detect-ai-generated-text/leaderboard',
    },
    'Predicting Student Performance from Game Play': {
      desc: 'Achieved top 4% on leaderboard by developing ML model, feature engineering, and utilizing XG-Boost algorithm to process and analyze large dataset, demonstrating ability to handle sizable data and extract insights, with F1 score of 0.68 showcasing effective modeling of large-scale educational datasets. ',
      techStack: 'Python, Machine Learning, XG-Boost, Feature Engineering',
      link: 'https://github.com/jojo142/MoneyInMotionPerformancefromGamePlay',
      open: 'https://www.kaggle.com/competitions/predict-student-performance-from-game-play/leaderboard',
    },
    'Attendence AI': {
      desc: 'Uses AI to facial recognition in order to track student attendance in the classroom. The system employs a combination of cameras, microphones, and AI algorithms to accurately identify and mark individual students present or absent. Then identifies individual students and mark them as present and reports whoevers absent. Teachers gets a key to log in for specific class that trains the ai to recognize students in that class. ',
      techStack: 'Azure AI, Azure Cosmos DB,  Python, Dart, Flutter, Firebase',
      link: 'https://github.com/jojo142/AttendenceAI',
      open: 'https://microsoftaiclassroom.devpost.com/?ref_feature=challenge&ref_medium=discover',
    },

    'Conversion Calculator and Cyber Security Tools': {
      desc: 'A conversion calculator that converts between different units of measurement. AI phishing tool uses a combination of big data and deep learning algorithms for early-stage detection of phishing emails and other malicious activities. The tool can incorporate ML models to detect business email compromise phishing attacks and predictive models for phishing detection.',
      techStack: 'OCaml, NLP, Python, ML',
      link: 'https://github.com/jojo142/ConversionCalculator',
      open: 'https://github.com/jojo142/CyberSecurityTools',
    },
    'TicTacToe Game': {
      desc: 'A console game lets you choose a position for your [ X or O] and returns a new board with the input. You can play multiple rounds with it. In order to play you need to download the zip file and run it on your local device. Make sure to have java complier installed for it to run smoothly.',
      techStack: 'Java',
      link: 'https://github.com/jojo142/Tic-Tac-Toe-Game',
      open: '',
    },
    'Tetris Game': {
      desc: 'Experience the timeless excitement of Tetris through a Java-based implementation.The game features intuitive controls and a dynamic interface that brings the beloved puzzle game to life. ',
      techStack: 'Java, Blender',
      link: 'https://github.com/jojo142/TetrisGame',
      open: '#',
    },

    'Maze Game': {
      desc: ' A console game that lets you move around [ up, down, left and right] to collect coins and then trade at the end with a trader located at the end of the matrix. Its a game inspired by the concept of investing into stock market.',
      techStack: 'Java',
      link: 'https://github.com/jojo142/Maze-Game',
      open: '',
    },
    'Guessing Game': {
      desc: 'A console game where you guess number to win. I will tell you if the number is higher or lower.',
      techStack: 'Python',
      link: 'https://github.com/jojo142/Guessing-Game',
      open: '',
    },
	};
  return (
      <div id="projects">
        <div className="section-header ">
          <span className="section-title">/ more projects</span>
        </div>
        <div className="project-container">
          <ul className="projects-grid">
            {Object.keys(projects).map((key, i) => (
              <FadeInSection delay={`${i + 1}00ms`}>
                <li className="projects-card">
                  <div className="card-header">
                    <div className="folder-icon">
                      <FolderOpenRoundedIcon
                        style={{ fontSize: 35 }}
                      ></FolderOpenRoundedIcon>
                    </div>
                    <Mylinks
                      githubLink={projects[key]["link"]}
                      openLink={projects[key]["open"]}
                    ></Mylinks>
                  </div>

                  <div className="card-title">{key}</div>
                  <div className="card-desc">{projects[key]["desc"]}</div>
                  <div className="card-tech">{projects[key]["techStack"]}</div>
                </li>
              </FadeInSection>
            ))}
          </ul>
        </div>
      </div>
    );
  }
}

export default Projects;