import React from 'react';
import Intro from './components/intro.js';
import SideBarNav from './components/SideBarNav.js';
import About from './components/about.js';
import Experience from './components/experience.js';
import Projects from './components/projects.js';
import Footer from './components/Footer.js';
import AI from './components/ai.js';
import './App.css';
import './styles/Global.css';
import Education from './components/education.js';
import UX from './components/uxdesign.js';
import Software from './components/software.js';

function App() {
	return (
	  <div className="App">
		<div id="content" style={{ marginTop: 0, padding: 0 }}>
		  <Intro />
		  <About />
		  <Education/>
		  <Experience />
		  <Software />
		  <Projects />
		  <UX />
		  <AI></AI>
		  <Footer />
		</div>
		
		<SideBarNav />
	  </div>
	);
  }

export default App;
