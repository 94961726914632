import React from 'react';
import FadeInSection from './FadeInSection';
import '../styles/education.css';
import Ecard from './ecard.js';

class Education extends React.Component {
	constructor() {
		super();
		this.state = {
			expanded: true,
			activeKey: '1',
			visible: true,
		};
		this.handleSelect = this.handleSelect.bind(this);
	}
	handleSelect(eventKey) {
		this.setState({
			activeKey: eventKey,
		});
	}
	render() {
		return (
            <div id="education">
				<FadeInSection>
					<div className="section-header">
						<span  className="section-title"><b>/education</b></span>
					</div>
					<div className="aboutContent">
						<div className="aboutDescription">
							<p>
                                I am very curious person. Here's an overview of my academics:
                                
                            </p>

						</div>
					
					</div>

					<Ecard></Ecard>
				</FadeInSection>
			</div>
        )
    }
}
export default Education;