import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import FadeInSection from './FadeInSection';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import '../styles/experience.css';
import { duration } from '@mui/material';

const isHorizontal = window.innerWidth < 600;

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	if (isHorizontal) {
		return (
			<div
				role="tabpanel"
				hidden={value !== index}
				id={`full-width-tabpanel-${index}`}
				aria-labelledby={`full-width-tab-${index}`}
				{...other}>
				{value === index && (
					<Box p={3}>
						<Typography>{children}</Typography>
					</Box>
				)}
			</div>
		);
	} else {
		return (
			<div
				role="tabpanel"
				hidden={value !== index}
				id={`vertical-tabpanel`}
				{...other}>
				{value === index && (
					<Box p={3}>
						<Typography>{children}</Typography>
					</Box>
				)}
			</div>
		);
	}
}
TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};
function a11yProps(index) {
	if (isHorizontal) {
		return {
			id: `full-width-tab-${index}`,
			'aria-controls': `full-width-tabpanel-${index}`,
		};
	} else {
		return {
			id: `vertical-tab-${index}`,
		};
	}
}

const JobList = () => {

	const [value, setValue] = React.useState(0);
	const experienceItems = {
		'Alkermes':{
			jobTitle: 'Data Analyst Intern @',
			companyUrl: 'https://www.alkermes.com',
			duration: 'May 2024 - Current',
			desc:[
				'Designed and deployed an automated ETL pipeline to refresh different pharmacy’s weekly inventory, purchase and dispense data from vendor reducing manual effort by 90% and improving data accuracy by 80%.',
				'Transitioned data processes from EXCEL to SNOWFLAKE for scalability.',
				'Built interactive dashboards in Tableau to create better access of data for business leaders. Created SQL query library for large datasets and integrated it with Excel to optimize the data processing for commercial KPI reporting.',
		]
		},
		'Vegan Friendly': {
			jobTitle: 'Consultant @',
			companyUrl: 'https://www.vegan-friendly.com/',
			duration: 'August 2023 - December 2023',
			desc: [
				' Conducted research on vegan trends, consumer preferences, new vegan products and different categories of vegan recipes in order to identify opportunities for growth and innovation.', 
				' Responsible for providing advice and expertise to clients to help them improve their business performance in terms of operations, profitability, management, structure and strategy.',
				' Presenting findings and recommendations to clients about Vegan market and how to improve their business.',
				' Collaborated with team members to adhere client needs and ensure smooth operations.',
			],
		},
		'FIZZ Social': {
			jobTitle: 'Ambassador @ ',
			companyUrl:'https://fizz.social/',
			duration: 'August 2023 - Present',
			desc: [
				'Encouraged students to join the FIZZ Social platform and helped them to create their profile.',
				'Educated students about the FIZZ Social platform and its benefits.',
				'Promoted FIZZ Social on social media platforms.',
				'Increased user engagement by 30% by creating posts that were relevant to the target audience.',
			],
		},
		'Freelance':{
			jobTitle: 'Freelance Web Deverloper @ ',
			duration: 'August 2022 - Present',
			desc: [
				' Created restuarant websites for clients using React.js, Node.js, Express.js and MongoDB.',
				' Used Material-UI to design the website and deployed it using Heroku.',
				' Created a secure login page using authentication protocols such as JWT (JSON Web Tokens) and OAuth to ensure user data privacy and security.',
				' Designed and implemented a seamless checkout process for an e-commerce platform, including a delivery information form and integration with payment gateways to facilitate smooth and secure transactions for customers.',
				' Open to work. Feel free to contact me.',
			],
		},
		INBE: {
			jobTitle: 'Software Engineering Intern @ ',
			companyUrl: 'https://www.inbe.ca/en/',
			duration: 'January 2023 - June 2023',
			desc: [
				' Ensured that the software developed meets the requirements of the client and is delivered within the deadline.',
				' Collaborated with team members to reduce discrepancies in the knowledge gap of the members for the tools used in the project.',
				' Used Trello, Canban, Slack and Jira to manage the project and communicate with the team.',
				' Given the opportunity to work on the back-end of the project and Github pull requests were reviewed by the team.',
				' Created and maintained technical documentation.',

			],
		},
		Weelrn: {
			jobTitle: 'Software Engineering Intern @ ',
			companyUrl: 'https://www.weelrn.com/',
			duration: 'August 2022 - December 2022',
			desc: [
				' Researched the project scope and the API and the tools used in the project to reduce the knowledge gap.',
				' Created front-end of the project using React.js and Material-UI and used Cloud based services to deploy the project.',
				' Tested the project using Pytest for the back-end and Cypress for the front-end.',
				' Communicated work progress with the team using Slack and Trello.',
			],
		},
		"Tamid Group": {
			jobTitle: 'Tech consultant @ ',
			companyUrl: 'https://tamidgroup.org/tamid-at-brandeis/',
			duration: 'August 2022 - December 2023',
			desc: [
				' Worked on multiple projects that involved solving real world problems for clients.',
				' Collaborated with team members to ensure that the project is delivered within the deadline.',
				' Used Slack to manage the project and communicate with the team.',
				' Attended general and weekly meetings and workshops to learn about the latest technologies and tools and discuss the progress of the project.',
				' Networked with alumni and industry professionals to learn about their experiences and gain insights about the industry.',
			],
		},
		'Tutoring': {
			jobTitle: '3D animation',
			duration: 'March 2020 - December 2022',
			desc: [
				' Tutored 2 students on how to make 3D animations using Blender.',
				' Made Youtube videos to teach Blender.',
			],
		},
		Starbucks: {
			jobTitle: 'Barista @',
			duration: 'March 2018 - June 2022',
			desc: [
				' Prepared and served beverages',
				' Handled cash and credit card transactions and maintained accurate records of all transactions.',
				' Increased sales of merchandise at the store by 20% by educating customers about the products.',
				' Helped customers with their orders and provided them with a positive experience.',

			],
		},
	};
	const handleChange = (event, newValue) => {
		setValue(newValue);
	};
	return (
		<div
			className="wrapper"
			// className={classes.root}
		>
			
			<Tabs
				orientation={!isHorizontal ? 'vertical' : null}
				variant={isHorizontal ? 'fullWidth' : 'scrollable'}
				value={value}
				onChange={handleChange}
				className="tabs">
				{Object.keys(experienceItems).map((key, i) => (
					<Tab label={key} {...a11yProps(i)} />
				))}
			</Tabs>
			
			{Object.keys(experienceItems).map((key, i) => (
				<TabPanel value={value} index={i} className="tabPanel" key={i}>
					<span className="joblist-job-title">
						{experienceItems[key]['jobTitle'] + ' '}
					</span>
					<span className="joblist-job-company">
						<a
							href={experienceItems[key]['companyUrl']}
							target="_blank"
							rel="noopener noreferrer"
							className="company-link"
						>
							{key}
						</a>
					</span>
					<div className="joblist-duration">
						{experienceItems[key]['duration']}
					</div>
					<ul className="job-description">
						{experienceItems[key]['desc'].map((descItem, j) => (
							<FadeInSection delay={`${j + 1}00ms`} key={j}>
								<li>{descItem}</li>
							</FadeInSection>
						))}
					</ul>
				</TabPanel>
			))}

		</div>
	);
};
export default JobList;
