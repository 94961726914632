import styles from "../styles/uxdesign.css";
import Glide from '@glidejs/glide';
import '@glidejs/glide/dist/css/glide.core.min.css';
import '@glidejs/glide/dist/css/glide.theme.min.css';
import React, { useEffect, useRef } from 'react';
import FindInPageIcon from '@mui/icons-material/FindInPage';



const UX = () => {
	const glideRef = useRef(null);
  
	useEffect(() => {
	  if (glideRef.current) {
		const glide = new Glide(glideRef.current);
		glide.mount();
	  }
	}, []);
		
	const spotlightProjects = {
	  "Schema for Attendence AI": {
		title: "Schema for Attendence AI",
		desc:
		  "Schema for a web application that uses facial recognition to take attendence for students in a classroom.",
		techStack: "Figma",
		figma: "https://dribbble.com/samiyuh",
		image: "/ux/Attendence.png"
	  },
	  "Black marlin submersible": {
		title: "Black Marlin Submersible Design",
		desc:
		  "Designed for a submersible that can be used to explore the ocean. Inspired by Black Marlin fish which is considered fast and agile. ",
		techStack: "IZEA and Figma",
		figma: "https://dribbble.com/samiyuh",
		image: "/ux/bms.png"
	  },
	  "Restaurant Mockup": {
		title: "Mockup for a Restaurant Website",
		desc:
		  "Mockup for restaurant website during my freelancing experience. ",
		techStack: "React.js, Material UI, Chart.js",
		figma: "https://dribbble.com/samiyuh",
		image: "/ux/res.png"
	  },
	  "Tradesense Mockup": {
		title: "Mockup for Tradesense.io ",
		desc:
		  " Created a mockup for mobile and desktop for an app that allows users to track their stock portfolio and simulate trades.",
		techStack: "Figma and Canva",
		figma: "https://dribbble.com/samiyuh",
		image: "/ux/trade.png"
	  },
	  "Makeup Today": {
		title: "High fidelity prototype for Makeup Today",
		desc:
		  "I created a High fidelity prototype for a web application that allows users to try on makeup virtually. ",
		techStack: "Figma and Canva",
		figma:"https://www.figma.com/proto/U403MQxoSUNqICeYDLds2v/Samiya's-Makeup-Today?node-id=2-53&starting-point-node-id=2%3A53",
		image: "/ux/MakeupToday.png"
	  },
	  "Moodboard": {
		title: "Sunset Themed Moodboard",
		desc:
		  "I created a moodboard for a web application that allows users to try on makeup virtually. ",
		techStack: "Figma and Canva",
		figma: "https://dribbble.com/samiyuh",
		image: "/ux/mood.png"
	  },

	  }
  
	return (
		<div id="uxdesign" className={styles.projects}>
		  <div className="section-header">
			<span className="section-title">/ ux-design </span>
		  </div>
		  <div className="spotlight-container" ref={glideRef}>
			<div className="uxdesign-glide__track" data-glide-el="track">
			  <ul className="glide__slides">
				{Object.keys(spotlightProjects).map((key, index) => (
				  <li className="glide__slide" key={index}>
					<div className="spotlight-card">
					  <div className="spotlight-header">
						<img
						  className="spotlight-image"
						  src={spotlightProjects[key]["image"]}
						  alt={key}
						/>
						<div className="spotlight-title">
						  {spotlightProjects[key]["title"]}
						</div>
						<div className="spotlight-desc">
						  {spotlightProjects[key]["desc"]}
						</div>
						<div className="spotlight-tech">
						  {spotlightProjects[key]["techStack"]}
						</div>
						<a href={spotlightProjects[key]["figma"]} target="_blank" rel="noopener noreferrer">
						<FindInPageIcon style={{ fontSize: 32, padding: '2px', color: 'var(--highlight)' }}></FindInPageIcon></a>
					  </div>
					</div>
				  </li>
				))}
			  </ul>
			</div>
		  </div>
		</div>
	  );
	};
	
	export default UX;