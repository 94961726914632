import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import FadeInSection from './FadeInSection';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import '../styles/experience.css';

const isHorizontal = window.innerWidth < 600;

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	if (isHorizontal) {
		return (
			<div
				role="tabpanel"
				hidden={value !== index}
				id={`full-width-tabpanel-${index}`}
				aria-labelledby={`full-width-tab-${index}`}
				{...other}>
				{value === index && (
					<Box p={3}>
						<Typography>{children}</Typography>
					</Box>
				)}
			</div>
		);
	} else {
		return (
			<div
				role="tabpanel"
				hidden={value !== index}
				id={`vertical-tabpanel`}
				{...other}>
				{value === index && (
					<Box p={3}>
						<Typography>{children}</Typography>
					</Box>
				)}
			</div>
		);
	}
}
TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};
function a11yProps(index) {
	if (isHorizontal) {
		return {
			id: `full-width-tab-${index}`,
			'aria-controls': `full-width-tabpanel-${index}`,
		};
	} else {
		return {
			id: `vertical-tab-${index}`,
		};
	}
}
const Ecard = () => {
	const [value, setValue] = React.useState(0);
	const experienceItems = {
		'Brandeis University': {
		    jobTitle: 'Undergraduate @',
			duration: 'August 2021 - May 2025',
			desc: [
                'Studying to get B.S. degree in CS and B.A degree in Business Management.',
				'Along with classes, I was part of Brandeis TAMID as a Tech consultant.',
				'I also was the Treaserur of Investment Club and Co- VP of Consulting Club',
                'I also participated in Intramural Sport for Badminton.',
				'I like to make charcoal art in the art studio while listening to music.',
			],
		},
		
		Certifications: {
			jobTitle: 'My ',
			duration: 'JUL 2022 - PRESENT',
			desc: [
				'JPMorgan Chase Quantitative Research Virtual Experience Program(2023)', 
                'Machine Learning Operations (MLOps) Challenge(2023)',
                'Google Data Analytics Certifications(2022)', 
				'Google Program Management Certification(2022)',
				'Google UI UX Design Certification(2022)',]
				
		},
		'Courses Taken': {
			jobTitle: '',
			duration: 'March 2022 - December 2022',
			desc: [
				'Computer Science: Data Structures, Operating Systems, Discrete Structures, NLP, Machine Learning, Fundamentals of AI, 3D Animations(Blender), Linear Algebra, Calculus, Compiler Design, Computer Vision, Computer systems ',
				'Business Management: Marketing Management, Organizational Behavior, Econometrics, Data Analytics(Using R and SQl), Finance and Statictics',

			],
		},
	};
	const handleChange = (event, newValue) => {
		setValue(newValue);
	};
	return (
		<div
			className="wrapper"
			// className={classes.root}
		>
			<Tabs
				orientation={!isHorizontal ? 'vertical' : null}
				variant={isHorizontal ? 'fullWidth' : 'scrollable'}
				value={value}
				onChange={handleChange}
				className="tabs">
				{Object.keys(experienceItems).map((key, i) => (
					<Tab label={key} {...a11yProps(i)} />
				))}
			</Tabs>
			{Object.keys(experienceItems).map((key, i) => (
				<TabPanel value={value} index={i} className="tabPanel">
					<span className="joblist-job-title">
						{experienceItems[key]['jobTitle'] + ' '}
					</span>
					<span className="joblist-job-company">{key}</span>
					<div className="joblist-duration">
						{experienceItems[key]['duration']}
					</div>
					<ul className="job-description">
						{experienceItems[key]['desc'].map(function (descItem, i) {
							return (
								<FadeInSection delay={`${i + 1}00ms`}>
									<li key={i}>{descItem}</li>
								</FadeInSection>
							);
						})}
					</ul>
				</TabPanel>
			))}
		</div>
	);
};
export default Ecard;
